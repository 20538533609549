import { Clinic } from "@/services/Clinic"


export class ClinicGroup {

    id = null
    name = null
    description = null
    clinics = null

    constructor (data) {
        this.id = data.id
        this.name = data.name
        this.description = data.description
        this.clinics = this.initClinics(data.clinics)
    }

    // data is clinics[]
    initClinics (data) {
        const clinics = []
        for (const rawClinic of data) {
            const clinic = new Clinic(rawClinic)
            // only active clinics
            if (clinic.active) {
                clinics.push(clinic)
            }
        }
        return clinics
    }
}
